<template>
    <div v-if="$can('correccion.menu.ajuste')">
        <ModuloAjusteSalida 
            :nuevaventanaid="1"
            :estadomodulo="`AGREGAR`"
        />
    </div>
</template>
    
<script>
    import ModuloAjusteSalida from './Modulos/ModuloAjusteSalida.vue'

    export default {
    
        components: {
            ModuloAjusteSalida
        },
    
        data () {
            return {
                spinnerloading: false
            }
        }
    }
</script>