<template>
    <div v-if="$can('correccion.submenu.ajuste.inventario.salida')">
        <a-row type="flex" :gutter="[8,8]">
            <a-col class="flex-card-left" flex="1 1 75%">
                <a-row type="flex" :gutter="[2,2]">

                    <a-col flex="1 0 20%">
                        <a-button
                            size="small" 
                            @click="habilitarOpciones()"
                            >
                            <span v-if="!isBlock"><a-icon type="lock" /> Habilitar</span>
                            <span v-if="isBlock"><a-icon type="unlock" /> Deshabilitar</span>
                        </a-button>
                        <a-button 
                            v-if="$can('correccion.busqueda.de.producto.en.ajuste.inventario.salidas')"
                            size="small"
                            type="primary"
                            @click="openModalBuscarProducto()"
                            >
                            <a-icon type="search" /> Buscar Producto
                        </a-button>
                    </a-col>

                    <a-col flex="1 0 10%" v-if="$can('correccion.ajuste.inventario.salida.por.sucursal.de.origen')">
                        <!--SELECT ALMACEN-->
                        <span class="d-sm-block"><strong>Sucursal:</strong></span>
                        <a-select 
                            :disabled="!isBlock" 
                            :value="consulta.almacen_id" 
                            @change="onChangeAlmacen"
                            size="large"
                            style="width: 100%"
                            >
                            <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                        <!--SELECT ALMACEN-->
                    </a-col>

                    <a-col flex="1 0 40%">
                        <span class="d-sm-block"><strong>Observaciones:</strong></span>
                        <a-textarea v-model="consulta.observaciones" placeholder="Observaciones de Ajuste" :rows="1" />
                    </a-col>
                </a-row>

                <a-row type="flex" :gutter="[8,8]">
                    <a-col class="flex-card-codigo" flex="1 0 25%">
                        <center>
                            <label>CODIGO BARRAS: 
                                <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F1)</span>
                            </label>
                        </center>
                        <SelectCodigoBarras
                            :almacenid="1"
                            :tipoprecioventaid="1"
                            :tiposalidaid="1"
                            :tipocambioid="0"
                            :listacambio="[]"
                            @successProductoStockCodigo="successProductoCodigo"
                        />      
                    </a-col>

                    <a-col class="flex-card-producto" flex="1 0 75%">
                        <center>
                            <label>BUSQUEDA DE PRODUCTOS PARA EL AJUSTE DE SALIDA: 
                                <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F2)</span> &nbsp;&nbsp; 
                                <span style="font-weight:700; font-size:11px; color: rgb(32, 80, 129);">Cerrar </span>
                                <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(ESC)</span>
                            </label>
                        </center>
                        <SelectScrollProductoStock
                            ref="selectScrollProductoStock" 
                            :almacenid="parseInt(consulta.almacen_id)"
                            :tipoprecioventaid="parseInt(consulta.tipoprecioventaID)"
                            :tiposalidaid="1"
                            :tipocambioid="0"
                            :listacambio="[]"
                            @successScrollProductoStock="successProducto"
                        />
                    </a-col>

                    <a-col class="flex-card-tabla" flex="1 1 100%">
                        <TablaAjustes
                            ref="tablaproductos"
                            :nuevaventanaajusteid="nuevaventanaid"
                            :listaproducto="productoElegido"
                            @subtotal="successSubtotal"
                            @descuento="successDescuento"
                            @total="successTotal"
                            @productos="successTablaProducto"
                        />
                    </a-col>
                </a-row>
            </a-col>
            
            <a-col class="flex-card-right" flex="1 0 25%">
                <div style="height: 36%; background-color:#000000; padding-top: 10px; overflow-x: scroll;">
                    <center><label style="color:#fff;">Precio Total</label></center>
                    <center><font size="7" color="#0ed104"><strong><p>{{ ViewSubtotal }}</p></strong></font></center>
                </div>

                <h4 class="titulo-venta-color"><center>AJUSTE DE SALIDA</center></h4>
                <h2 class="codigodeventa">{{ consulta.codigoAjuste }}</h2>

                <br>

                <div class="box">
					<button 
                        type="button" 
                        id="EnviarInformacion" 
                        class="btn btn-block btn-lg" 
                        style="background-color:var(--element);" 
                        @click.once="realizarAjuste()"
                        :key="buttonKeyAjuste"
                        >
                        <font size=4>
                            <center>
                                <strong style="color:white;">REALIZAR AJUSTE<span style="font-size:14px;">(F9)</span></strong>
                            </center>
                        </font>
                    </button>
				</div>

                <div class="box">
					<button 
                        type="button" 
                        id="EnviarInformacion" 
                        class="btn btn-block btn-lg" 
                        style="background-color:#ff3333;" 
                        @click="eliminarListaProductos()" 
                        >
                        <font size=2>
                            <center>
                                <strong style="color:white;">ACTUALIZAR LISTA DE ITEMS</strong>
                            </center>
                        </font>
                    </button>
				</div>
            </a-col>
        </a-row>

        <!--PRODUCTO PROVEEDOR-->
		<a-modal
            v-model="modalBuscarProducto"
            :title="false"
            :closable="false"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1250"
            :zIndex="1040"
            >

            <div class="row mb-12">
                <ModuloProductoProveedor 
                    :utilidadporcentaje="config.utilidadporcentaje"
                    :statecodigobarradefault="config.statecodigobarradefault" 
                    :disabledvencimiento="config.disabledvencimiento" 
                    :preciocifradecimal="config.preciocifradecimal" 
                    :preciocifraentera="config.preciocifraentera" 
                    :precioreglatres="config.precioreglatres"  
                    :sessionuserid="config.userid"
                    :monedasimbolo="config.monedasimbolo"
                    :almacenid="config.almacenid"			
                /> 
            </div>

            <template slot="footer">
                <a-button key="back" @click="closeModalBuscarProducto()">
                    CERRAR MODAL
                </a-button>
            </template>
        </a-modal>
        <!--PRODUCTO PROVEEDOR-->

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
	import moment from 'moment'
    import Swal from 'sweetalert2'
	import * as utils from '@/utils/utils'
    import Cookies from 'js-cookie'
    import { mapGetters, mapActions } from 'vuex'
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
    import 'moment/locale/es'
    import SelectScrollProductoStock from '../../../components/SelectScrollProductoStock.vue'
    import SelectCodigoBarras from '../../../components/SelectCodigoBarras.vue'
    import TablaAjustes from '../../../components/TablaAjustes.vue'
	import ModuloProductoProveedor from '../../Inventario/Modulos/ModuloProductoProveedor.vue'

    import {
        ALMACEN_SISTEMA_KEY_ID,
        SISTEMA_USUARIO
    } from '@/utils/constants'

    export default {

        props: [
            'nuevaventanaid', 
            'estadomodulo'
        ],

        components: {
            SelectScrollProductoStock,
            SelectCodigoBarras,
            TablaAjustes,
            ModuloProductoProveedor
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
                moment,
                locale,
                utils,
                productoElegido: {},
                consulta: {
                    almacen_id: '',
                    proveedor_id: '',
                    tipoprecioventaID: '1',
                    usuario: 'Seleccionar',
                    tiposalida_id: 3,
                    tipo_venta_id: '1',
                    observaciones: '',
                    fechaCredito: '',
                    fechadeventa: '',
                    productos: [],
                    subtotal: '',
                    descuento: '',
                    total: '',
                    descuentoPorcentajeManual: 'Seleccionar Descuento',
                    descuentoPorcentajeSeleccion: '',
                    descuentoPorcentajeNombre: '',
                    descuentoMonedaMonto: '',
                    clienteID: '',
                    clienteNombre: 'SIN NOMBRE',
                    clienteNit: '0',
                    codigoVenta: '',
                    codigoAjuste: '',
                    importePagar: '',
                    cambio: ''
                },
                isBlock: false,
                spinnerloading: false,
                modalBuscarProducto: false,
                buttonKeyAjuste: 400,
                isDisabledButton: this.$can('manager.arqueo.de.caja')
            }
        },

        created () {
            this.cargarAlmacenProductos()
            this.getAlmacenListar()
            this.cargarUsuarios()
            this.cargarDatos()
            this.cargarProveedor()
            this.comandkeys()
        },

        computed: {
            ...mapGetters('almacen', ['almacenes']),
            ...mapGetters('usuario', ['usuarios']),
            ...mapGetters('auth', ['user']),
            ...mapGetters('configuracion', ['config']),
            ...mapGetters('proveedor', ['proveedores']),

            calculoCambio () {
                const importeTotal = parseFloat(this.consulta.importePagar)
                const subtotal = this.consulta.total
                return (!isNaN(importeTotal) && !isNaN(subtotal) && importeTotal > 0) ? Math.max(0, importeTotal - subtotal) : 0
            },

            ViewSubtotal () {
                return utils.formatMoney(this.consulta.subtotal, this.config.ventadecimal, '.', '')
            },

            ViewSubtotalTotalConDescuento () {
                return utils.formatMoney(this.consulta.total, this.config.ventadecimal, '.', '')
            },

            ViewCalculoCambio () {
                return utils.formatMoney(this.calculoCambio, this.config.ventadecimal, '.', '')
            }
        },

        methods: {
            ...mapActions('producto', ['getListaScrollMasivoProductoStock']),
            ...mapActions('almacen', ['getAlmacenListar']),
            ...mapActions('usuario', ['getUsuarioListar']),
            ...mapActions('ajuste', ['getDatosAjuste', 'guardarAjuste']),
            ...mapActions('proveedor', ['getProveedorListado']),

            comandkeys () {
				var self = this

				var ENTER_KEY_CLOSE = 27
				var ENTER_KEY_SUB_CLOSE = 27
				var ENTER_KEY_CONFIRMAR = 13

				var ENTER_KEY_CODIGO_BARRA = 112
				var ENTER_KEY_LISTA = 113
				var ENTER_KEY_IMPORTE = 114
				var ENTER_KEY_CLIENTE = 115
				var ENTER_KEY_TIPO_SALIDA = 116
				var ENTER_KEY_AGREGAR_CLIENTE = 117
				var ENTER_KEY_AGREGAR_SOLICITANTE = 118
				var ENTER_KEY_GUARDAR_CLIENTE = 119
				var ENTER_KEY_GUARDAR_SALIDA = 120
				var ENTER_KEY_MAYUS = 16
				var ENTER_KEY_O = 79
				var ENTER_KEY_J = 74
				var ENTER_KEY_CTRL = 17
				var ENTER_KEY_PLUS = 61

				document.addEventListener('keydown', function (e) {
                    const code = e.keyCode || e.which
                    const habilitarControlCodigoBarras = self.config.habilitarcontrolcodigobarras === 'ACTIVADO'
                    const codesToPrevent = [74, 61, 17, 27, 112, 113, 114, 115, 116, 117, 118, 119, 120]

                    if ((habilitarControlCodigoBarras && codesToPrevent.includes(code)) || (!habilitarControlCodigoBarras && codesToPrevent.slice(1).includes(code))) {
                        e.preventDefault()
                        
                        switch (code) {
                            case ENTER_KEY_TIPO_SALIDA:
                                console.log('F5')
                                break
                            case ENTER_KEY_J:
                            case ENTER_KEY_PLUS:
                            case ENTER_KEY_CTRL:
                            case ENTER_KEY_CODIGO_BARRA:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                break
                            case ENTER_KEY_CLOSE:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                document.getElementById('CerrarModalCliente').click()
                                break
                            case ENTER_KEY_SUB_CLOSE:
                                document.querySelector('.swal2-cancel').click()
                                break
                            case ENTER_KEY_CONFIRMAR:
                                document.querySelector('.swal2-confirm').click()
                                document.getElementById('CodigoBarrasOnfocus').dispatchEvent(new KeyboardEvent('keypress'))
                                break
                            case ENTER_KEY_GUARDAR_SALIDA:
                                document.getElementById('EnviarInformacion').click()
                                break
                            case ENTER_KEY_LISTA:
                                document.getElementById('productoSelect').click()
                                break
                            case ENTER_KEY_IMPORTE:
                                document.getElementById('ImportePagar').focus()
                                document.getElementById('ImportePagar').select()
                                break
                            case ENTER_KEY_CLIENTE:
                                break
                            case ENTER_KEY_AGREGAR_SOLICITANTE:
                                document.getElementById('InputSolicitante').focus()
                                break
                            case ENTER_KEY_AGREGAR_CLIENTE:
                                document.getElementById('AgregarCliente').click()
                                break
                            case ENTER_KEY_GUARDAR_CLIENTE:
                                document.getElementById('GuardarCliente').click()
                                break
                            default:
                                break
                        }
                    }
                })
			},

            habilitarOpciones () {
                this.isBlock = !this.isBlock
            },

            cargarAlmacenProductos () {
				this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
            },

            cargarUsuarios () {
				this.consulta.usuario = (this.user) ? this.user.usuario : Cookies.get(SISTEMA_USUARIO)
            },

            cargarProductosMasivos () {
                this.getListaScrollMasivoProductoStock(this.consulta)
            },

            cargarProveedor () {
                this.getProveedorListado()
                .then(response => { 
                    this.consulta.proveedor_id = Object.values(this.proveedores).filter(x => typeof x !== 'undefined').shift().proveedor_id
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },

            cargarDatos () {
                this.getDatosAjuste(this.consulta)
                .then(response => { 
                    if (response.status === 200) {
						this.consulta.codigoAjuste = response.data.ajuste.codigoAjuste
					}
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },

            onChangeAlmacen (value) {
				this.consulta.almacen_id = value
                this.cargarProductosMasivos()
                this.$refs.selectScrollProductoStock.verificarProductos()
            },

            // Resultado de Datos
            successProducto (value) {
                this.productoElegido = value
                this.$refs.tablaproductos.agregarProductoALaLista(value)
            },

            successProductoCodigo (value) {
                this.productoElegido = value
                this.$refs.tablaproductos.agregarProductoALaLista(value)
            },

            successSubtotal (value) {
                this.consulta.subtotal = value
            },

            successDescuento (value) {
                this.consulta.descuento = value
            },

            successTotal (value) {
                this.consulta.importePagar = utils.formatMoney(value, this.config.ventadecimal, '.', '')
                this.consulta.total = value
            },

            successTablaProducto (value) {
                this.consulta.productos = value
            },
            // Modales
            openVentanaNueva () {
				const ventana = parseInt(this.nuevaventanaid) + 1
				this.$emit('abrirNuevaVentana', ventana)
			},

            openModalBuscarProducto () {
				setTimeout(function () { 
                    this.modalBuscarProducto = true
                }.bind(this), 300)
			},

            closeModalBuscarProducto () {
                this.showModalIframeProducto = true
				this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                this.cargarDatos()

				setTimeout(function () {
                    this.modalBuscarProducto = false
                    this.showModalIframeProducto = false
                }.bind(this), 500)
			},

            // Operaciones
            realizarAjuste () {
				if (Object.entries(this.consulta.productos).length === 0) {
                    Swal.fire('Atencion!', 'Por favor seleccione un producto para vender y vuelva a intentar...', 'error')
                } else {
                    Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora (INTRO)',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder! (ESC)'
                    }).then((result) => {
                        if (result.value) {
							this.spinnerloading = true
                            this.consulta.cambio = this.calculoCambio
							
                            this.guardarAjuste(this.consulta)
                            .then(response => {
                                if (response.status === 201) {
                                    this.$refs.tablaproductos.vaciarListaProductos()
                                    this.cargarDatos()
                                    this.cargarProductosMasivos()

                                    setTimeout(function () {
                                        this.consulta.fechaCredito = ''
                                        this.consulta.clienteNit = 0
                                        this.consulta.clienteNombre = 'SIN NOMBRE'
                                        this.consulta.importePagar = 0

                                        const ajusteID = parseInt(response.data.ajusteID)
                                        if (ajusteID) {
                                            if (response.data.imprimirVentaTermica === 'ACTIVADO') {
                                                utils.openRecibo(`/reporte/ajuste/termica/${ajusteID}`)
                                            } else {
                                                utils.openRecibo(`/reporte/ajuste/reporte/carta/${ajusteID}`)
                                            }
                                        }

                                        this.spinnerloading = false
                                    }.bind(this), 300)
                                }
                            })
                            .catch(error => {
                                utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                            })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                        }
                    })
                }
				this.buttonKeyAjuste++
            },

            eliminarListaProductos () {
                this.spinnerloading = true
                this.$refs.tablaproductos.vaciarListaProductos()
                this.cargarDatos()
                this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                setTimeout(function () {        
                    this.spinnerloading = false
                }.bind(this), 500)
            }
        }
    }

</script>

<style>
    .codigodeventa {
        font-size:18px; 
        text-align:center; 
        background-color:#f3f3f3; 
        color: var(--element); 
        font-weight:700; 
        border-style: solid; 
        border-width: 1px;
        border-color: var(--element); 
        padding-top: 10px; 
        padding-bottom: 10px;
    }

    .border-importe {
        border-width: 1px;
    }
    .flex-card-left { max-width: 76%; }
    .flex-card-right { max-width: 24%; }
    .flex-card-codigo { max-width: 25%; }
    .flex-card-producto { max-width: 75%; }
    .flex-card-tabla { max-width: 100%; }

    @media screen and (min-width: 240px) and (max-width: 320px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 320px) and (max-width: 430px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 540px) and (max-width: 540px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 768px) and (max-width: 768px)  {
        .flex-card-left { max-width: 75%; }
        .flex-card-right { max-width: 25%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
    }
</style>